import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
let axios = require('axios')
let sessionstorage = require('sessionstorage')

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false, messages:'' }
  }

  handleAlert=()=>{
    if(this.state.messages && this.state.messages!==''){
      return(`label is-tooltip-danger tooltip is-tooltip-active`);
    }else{
      return("label");
    }
  }

  handleRc=()=>{
    if(this.state.showrc){
      return({display:"block"});
    }else{
      return({display:"none"});
    }
  }

  getMessages=()=>{
    return(this.state.messages);
  }

  handleChange = e => {
    if(e.target.name==='message'){
      if(e.target.value.length>1){
        if(this.state.messages&&this.state.messages.length>0){
          this.setState({messages:'',storedMessages:this.state.messages})
        }
      }else{
        if(this.state.storedMessages&&this.state.storedMessages.length>0){
          this.setState({messages:this.state.storedMessages,storedMessages:''})
        }
      }
    }
    this.setState({ [e.target.name]: e.target.value })
  }



  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    if(this.state['bot-field']&&this.state['bot-field'].trim()!==''){

    }else{
      let rcresp='';
      if(document.getElementById("g-recaptcha-response")){
        rcresp=document.getElementById("g-recaptcha-response").value;
      }
      axios.post('https://wesdata.com/api/auth', {
        email:this.state.email,
        password:this.state.password,
        "g-recaptcha-response":rcresp
      })
        .then((resp) => {
          let data=resp.data;
          console.log(data);
          if(data.error===''){
            this.setState({messages:''});
            this.setState({showrc:false});
            sessionstorage.setItem('wesdata', JSON.stringify(data))
            navigate(form.getAttribute('action'));
          }else{
            if(data.error=='timeout-or-duplicate'){
              window.location.reload();
            }else{
              this.setState({messages:data.error});
              if(data.displayrc){
                this.setState({showrc:true});
              }
            }
          }
        })
        .catch(error => alert(error))
    }
  }

  render() {
    return(<Layout>
        <Helmet>
        <script src="https://www.google.com/recaptcha/api.js" async defer></script>
        </Helmet>
        <section className="section" >
          <div className="container">
            <div className="content" style={{marginBottom:"15%"}}>
              <div className="columns">
                  <div className="column is-4"></div>
                  <div className="column is-4">
                    <h1>Sign In</h1>
                    <form 
                      name="contact"
                      method="post"
                      action="/dashboard"
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                      onSubmit={this.handleSubmit}
                    >
                      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                      <input type="hidden" name="form-name" value="contact" />
                      <div hidden>
                        <label>
                          Don’t fill this out:{' '}
                          <input name="bot-field" id="bot-field" onChange={this.handleChange} />
                        </label>
                      </div>
                      <div className="field">
                        <label className={this.handleAlert()}
                                        data-tooltip={this.getMessages()} htmlFor={'email'}>
                          Email
                        </label>
                        <div className="control">
                          <input
                            className="input"
                            type={'email'}
                            name={'email'}
                            onChange={this.handleChange}
                            id={'email'}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label  htmlFor={'password'}>
                          Password
                        </label>
                        <div className="control">
                          <input
                            className="input"
                            type={'password'}
                            name={'password'}
                            onChange={this.handleChange}
                            id={'password'}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="field">
                      <div className="g-recaptcha" data-sitekey="6Lc2fzgUAAAAAPvqgRCLoORj1PCDCR9W7IJ7hGhd" style={this.handleRc()}></div>
                      </div>
                      <div className="field">
                        <button className="button is-link" type="submit">
                          Sign In
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="column is-4"></div>
              </div>
            </div>
          </div>
        </section>
      </Layout>);
  }
}

export default Index;